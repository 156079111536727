import React from "react"

import * as S from "./styled"

const NavbarLinks = () => {
  return (
    <S.HeaderMenu>
      <S.HeaderMenuItem to="/">Home</S.HeaderMenuItem>
      <S.HeaderMenuItem to="/produtos">Produtos</S.HeaderMenuItem>
      <S.HeaderMenuItem to="/empresa">Empresa</S.HeaderMenuItem>
      <S.HeaderMenuItem to="/clientes">Clientes</S.HeaderMenuItem>
      <S.HeaderMenuItem to="/contato">Contato</S.HeaderMenuItem>
    </S.HeaderMenu>
  )
}

export default NavbarLinks
