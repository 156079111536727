import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Logo = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "geral/logotipo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.HeaderMenuItemLogo to="/">
      <S.HeaderLogo
        fluid={logo.childImageSharp.fluid}
        alt="Logotipo - AçoconSP."
      />
    </S.HeaderMenuItemLogo>
  )
}

export default Logo
