import React, { useState } from "react"

import Logo from "./Logo"
import NavbarLinks from "./NavbarLinks"

import * as S from "./styled"

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <S.Header>
      <S.HeaderWrapper>
        <Logo />
        <S.HeaderToggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <S.HeaderHamburguer open /> : <S.HeaderHamburguer />}
        </S.HeaderToggle>
        {navbarOpen ? (
          <S.HeaderNavBox onClick={() => setNavbarOpen(!navbarOpen)}>
            <NavbarLinks />
          </S.HeaderNavBox>
        ) : (
          <S.HeaderNavBox open>
            <NavbarLinks />
          </S.HeaderNavBox>
        )}
      </S.HeaderWrapper>
    </S.Header>
  )
}

export default Header
