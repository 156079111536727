import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const Footer = styled.footer`
  width: 100%;
  height: auto;
  display: block;
  background-color: var(--black);
  padding: 3rem 0;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FooterLogo = styled(Img)`
  width: 200px;
  height: auto;
`

export const FooterText = styled.p`
  color: var(--white);
  margin: 1.5rem;
  font-family: "Lato", sans-serif;

  ${media.lessThan("large")`
    text-align: center;
  `}
`
export const FooterSocial = styled.div``

export const FooterMade = styled.div`
  color: var(--white);
  margin: 1.5rem;
  font-family: "Lato", sans-serif;

  & a {
    text-decoration: none;
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }
`
