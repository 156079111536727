import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Footer = () => {
  const { logoImage } = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "geral/logotipo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.Footer>
      <S.FooterWrapper>
        <S.FooterLogo
          fluid={logoImage.childImageSharp.fluid}
          alt="Logotipo - AçoconSP"
        />
        <S.FooterText>
          Endereço: Rua Paulo Américo Russo, 759, Araras/SP
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telefone: (19) 3352-7759
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-mail: vendas@acoconsp.com.br
        </S.FooterText>
        <S.FooterSocial></S.FooterSocial>
        <S.FooterMade>
          Criado por{" "}
          <a href="https://www.darknblack.com">Dark n' Black iStudio</a>
        </S.FooterMade>
      </S.FooterWrapper>
    </S.Footer>
  )
}

export default Footer
